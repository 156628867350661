
.processingAudioPopupPageWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.processingAudioPopupBackdrop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    animation: backdropAnimation 0.3s ease-in-out;
}

.processingAudioPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 200px;
    background-color: white;
    border-radius: 5px;
    display: grid;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.50);
    animation: modalAnimation 0.3s ease-in-out forwards;
}

.processingAudioPopupHeader {
    /* height: 50px; */
    height: fit-content;
    background-color: #0283BE;
    border-radius: 5px 5px 0px 0px;
    display: grid;
    align-items: center;
}

.processingAudioPopupHeaderText {
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 10px;
}

.processingAudioPopupBody {
    display: grid;
    justify-items: center;
}

.processingAudioPopupBodyText {
    margin: 0px;
    text-align: center;
    padding: 10px;
}
