

:root {
  --primary-color: #0083be;
  --secondary-color: #1a517b;
  --primary-button-color: #b5d334;
  --scroll-notification-color: #2C8500;
}

body {
  background-color: #DFE7EB;
}

.App {
  height: 100vh;
}

.printReceiptContainer {

}

.printReceiptButtonContainer {
    position: sticky;
    bottom: 0;
    background-color: #3E3E3E;
    border-top: 1px solid #bbbbbb;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    padding: 10px;
    width: calc(100% - 20px);
}

.printReceiptButton {
    height: 40px;
    width: 100%;
    max-width: 300px;
    justify-self: start;
}

.printReceiptButton:nth-child(1) {
    justify-self: end;
}

@media print {
    .printReceiptButtonContainer {
        display:none;
    }
}