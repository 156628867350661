

.caseListPageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
    overflow: hidden;
    background-color: var(--new-primary-background-color);
    position: relative;
}

/* Begin Case List */

.caseListBodyPageWrapper {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

.caseListHeaderButtonContainer {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 10px;
    column-gap: 10px;
    background-color: rgba(0, 0, 0, 0);
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: end;
    z-index: 10;
    height: 70px;
}

.caseListHeaderButton {
    height: 40px;
    width: 150px;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-items: center;
    color: var(--new-text-color) !important;
}

.caseListHeaderMobileButton {
    display: none;
}

.caseListHeaderBurgerButton {
    display: none;
}

@media (max-width: 950px) {
    .caseListHeaderButton {
        display: none;
    }

    .caseListHeaderMobileButton {
        width: 45px;
        height: 40px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        color: var(--new-primary-color) !important;
    }
}

@media(max-width: 950px) {
    .caseListHeaderButtonContainer {
        column-gap: 0px;
    }

    .caseListHeaderMobileButton {
        display: none;
    }

    .caseListHeaderBurgerButton {
        width: 45px;
        height: 40px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        color: var(--new-primary-color) !important;
    }
}

.caseListHeaderButtonIcon {
    height: 22px;
    width: 22px;
    color: var(--new-primary-color);
}

.caseListLoadingContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin: auto;
    background-color: var(--new-primary-background-color);
}

.caseListLoadingWrapper {
    height: 110px;
    width: fit-content;
    align-self: center;
    justify-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 70px 40px;
}

.caseListContainer {
    background-color: var(--new-primary-background-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    row-gap: 10px;
    column-gap: 10px;
    padding: 0px 10px;
    overflow-y: auto;
    padding-bottom: 10px;
    overflow-x: hidden;
}

.caseListSectionWrapper {
    padding: 0px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;
}

.caseListSectionHeaderContainer {
    position: sticky;
    top: 0;
    height: 70px;
    background-color: var(--new-primary-background-color);
    left: 0;
    width: calc(100% + 20px);
    transform: translateX(-10px);
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    align-items: center;
    padding: 0px 10px;
    z-index: 9;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10); */
}

.castListSectionLogoText {
    font-size: 32px;
    color: var(--new-primary-color);
    margin: 0px;
    font-weight: bold;
}

@media (max-width: 1150px) {
    .caseListContainer {
        grid-template-columns: 1fr 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 3;
    }
}

/* @media (max-width: 900px) {
    .caseListContainer {
        grid-template-columns: 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 2;
    }
} */

@media (max-width: 950px) {
    .caseListContainer {
        grid-template-columns: 1fr 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 3;
    }
}

@media (max-width: 600px) {
    .caseListContainer {
        grid-template-columns: 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 2;
    }
}

@media (max-width: 385px) {
    .caseListSectionHeaderContainer {
        column-gap: 0px;
    }
}

.caseListSectionIcon {
    height: 40px;
    width: 40px;
    margin: 5px;
    display: inline-block;
    color: var(--new-primary-color);
    vertical-align: top;
}

.caseListSectionHeader {
    font-size: 32px;
    display: inline-block;
    margin: 5px;
    vertical-align: top;
    color: var(--new-tertiary-off-text-color);
}

@media (max-width: 1020px) {
    .caseListSectionHeader {
        font-size: 24px;
    }
}

@media (max-width: 385px) {
    .caseListSectionIcon {
        height: 30px;
        width: 30px;
    }

    .caseListSectionHeader {
        font-size: 20px;
    }
}

.caseListFooterContainer {
    display: grid;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--new-secondary-background-color);
    z-index: 2;
}

.caseListFooterSelectedSectionsWrapper {

}

.caseListFooterSelectedSectionsText {
    margin: 10px 0px;
    margin-left: 10px;
    font-weight: 600;
    color: var(--new-text-color);
}

.caseListFooterSelectedSectionsContainer {
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) inset;
    margin: 0px 10px;
    height: 70px;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

.caseListFooterSelectedSection {
    height: 70px;
    display: inline-grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 1fr 1fr;
    width: fit-content;
    min-width: 40px;
    margin-right: 10px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: pointer;
}

.caseListFooterSelectedSectionNumber {
    margin: 0px;
    color: var(--new-primary-color);
    font-weight: 600;
    font-size: 20px;
    padding: 0px 5px;
}

.caseListFooterSelectedSectionIconWrapper {
    background-color: var(--new-primary-color);
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.caseListFooterSelectedSectionIcon {
    color: white;
}


.caseListFooterButtonsWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;
    align-items: center;
    padding: 0px 10px;
    column-gap: 10px;
    height: 50px;
}

.caseListFooterButton {
    height: 35px;
    min-width: 200px;
    /* display: none; */
    transition-duration: 150ms;
}

.caseListFooterLoaderWrapper {
    min-width: 200px;
    height: 35px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.caseListFooterSecondaryButton {

}

.caseListFooterPrimaryButton {

}

@media(max-width: 950px) {
    .caseListFooterButton {
        min-width: 180px;
    }
}

@media(max-width: 950px) {
    .caseListFooterButtonsWrapper {
        grid-template-columns: 1fr 1fr;
    }

    .caseListFooterButton {
        min-width: 0px;
        width: 100%;
    }
}

.confirmationPopupCheckboxWrapper {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    margin-top: 15px;
}

.confirmationPopupCheckboxLabel {
    margin: 0px;
    color: var(--new-text-color);
}

.caseListLoadingText {
    color: var(--new-text-color);
}

.caseListNoCasesContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin-top: 70px;
}

.caseListNoCasesText {
    margin: 15px 0px;
}

.caseListViewHiddenCasesButton {
    height: 40px;
    width: 200px;
}

/* .caseListNoCasesTitle {
    font-size: 26px;
}

.caseListNoCasesContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 15px;
    width: 95%;
    max-width: 400px;
    left: 20%;
}

.caseListNoCasesText {
    margin: 20px 0px;
}

.caseListViewHiddenCasesButton {
    height: 40px;
    width: 200px;
} */

.caseListRowStyleListContainer {
    background-color: var(--new-primary-background-color);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    row-gap: 7px;
    padding: 0px 10px;
    overflow-y: auto;
    padding-bottom: 10px;
    overflow-x: hidden;
}

.caseListRowSectionHeaderContainer {
    position: sticky;
    top: 0;
    height: 100px;
    background-color: var(--new-primary-background-color);
    left: 0;
    width: 100%;
    transform: translateX(-10px);
    display: grid;
    grid-template-rows: auto auto;
    column-gap: 5px;
    align-items: center;
    padding: 0px 10px;
    z-index: 9;
    padding-bottom: 5px;
}

.caseListRowHeaderContainer {
    height: 70px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
}

.caseListRowLabelContainer {
    height: 30px;
    display: grid;
    padding: 0px 0px;
    justify-items: start;
    align-items: center;
}

.caseListRowLabel {
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    /* text-align: center; */
    cursor: pointer;
    color: var(--new-text-color);
}

.caseListRowContainer {
    display: grid;
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    border-radius: 3px;
    height: fit-content;
    min-height: 45px;
    /* grid-template-columns: 50px 100px 25% 25% 15% 10% calc(25% - 150px); */
    /* grid-template-columns: 50px 1fr 2fr 2fr 1fr 1fr 100px; */
    align-items: center;
    justify-items: start;
    cursor: pointer;
    transition-duration: 150ms;
}

.caseListRowContainer:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.55);
}

@media(min-width: 800px) {
    .caseListRowContainer:hover {
        transform: scale(1.01) !important;
        z-index: 5;
    }
}

.caseListRowIconContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 50px;
    border-radius: 3px 0px 0px 3px;
    background-color: var(--new-primary-color);
}

.caseListRowIcon {
    color: white;
    height: 25px;
    width: 25px;
}

.caseListRowCaseNumber {
    margin: 0px;
    /* text-align: center; */
    font-size: 24px;
    color: var(--new-primary-color);
    justify-self: center;
}

.caseListRowValue {
    margin: 0px;
    /* text-align: center; */
    font-size: 15px;
    padding: 10px 0px;
    color: var(--new-text-color);
}

.caseListRowMoreOptionsButton {
    height: 100%;
    width: 100%;
    border: 0px;
    box-shadow: inset 1px 0px 1px 0px rgba(0, 0, 0, 0.25);
    background-color: var(--new-primary-background-color);
    cursor: pointer;
}

.caseListRowMoreOptionsIcon {
    height: 17px;
    width: 17px;
    color: var(--new-text-color);
}

.moreOptionsPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 200px;
    z-index: 20;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: grid;
    grid-auto-flow: row;
    row-gap: 10px;
}

.moreOptionsPopupButton, .moreOptionsPopupReviewLaterButton {
    background-color: var(--new-secondary-background-color);
    color: var(--new-text-color);
    cursor: pointer;
    border-radius: 3px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    height: 35px;
    align-self: center;
    padding: 0px;
    font-size: 13px;
    width: 100%;
}

.moreOptionsPopupButton {
    
}

.moreOptionsPopupReviewLaterButton {
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
}
