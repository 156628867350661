

.topTenCasesModuleContainer {
    width: 100%;
    display: grid;
}

.topTenCasesTitleContainer {
    display: grid;
    grid-template-columns: 25% 40% 35%;
    border-bottom: 1px solid #EEEEEE;
}

.topTenCasesTitleText {
    font-size: 14px;
    margin: 10px 0px;
    justify-self: center;
    text-align: center;
    align-self: center;
}

.topTenCasesBodyContainer {
    display: grid;
    grid-template-columns: 25% 40% 35%;
}

.topTenCasesBodyContainer:nth-child(even) {
    background-color: #F8F8F8;
}

.topTenCasesBodyContainer:nth-last-child(1) {
    border-radius: 0px 0px 5px 5px;
}

.topTenCasesBodyText {
    font-size: 14px;
    margin: 10px 0px;
    justify-self: center;
    align-self: center;
    text-align: center;
}