

.caseListSidebar {
    background-color: var(--new-tertiary-background-color);
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    padding: 15px;
    overflow-y: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.caseListSidebarContentWrapper {
    max-width: 500px;
    margin: auto;
}

/* @media(max-width: 950px) {
    .caseListPageContainer {

    }
} */

.caseListLogoContainer {
    display: grid;
    grid-template-columns: 1fr;
}

.caseListLogo {
    height: 40px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.caseListDrawerCloseButton {
    display: none;
}

.caseListMobileNavButtonContainer {
    display: none;
}

.caseListMobileDrawerPageContainer {
    display: none;
}

.caseListSidebarWrapper {
    user-select: none;
    height: 100%;
    overflow-y: auto;
}

.caseListStyleToggle {
    display: grid;
    height: 40px;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}

.caseListStyleToggleOption {
    height: 100%;
    width: 100%;
    color: white;
    background-color: var(--new-off-text-color);
    border: none;
    cursor: pointer;
}

.caseListStyleOptionSelected {
    background-color: var(--new-secondary-color);
}

.caseListStyleToggleLoadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.caseListDropdownWrapper {
    display: grid;
    grid-template-rows: auto auto;
}

.dropdownWithButton {
    grid-template-columns: auto 50px;
}

.caseListDropdownWrapper > select {
    grid-row: 2;
}

.caseListSortDirectionButton {
    grid-column: 2;
    grid-row: 2;
    margin-left: 10px;
    height: 100%;
    cursor: pointer;
    background-color: var(--new-off-text-color);
    border: none;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: var(--new-secondary-color);
    font-size: 18px;
    position: relative;
}

.caseListSortDirectionIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media(max-width: 950px) {
    .caseListMobileDrawerPageContainer {
        position: absolute;
        display: initial;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        overflow: hidden;
    }
    
    .caseListMobileDrawerBackdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.7);
    }
    
    .caseListMobileDrawerContainer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 90%;
        width: 100%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
    }

    .caseListPageContainer {
        grid-template-columns: 1fr;
    }

    .caseListSidebar {
        width: calc(100% - 30px);
        border-radius: 10px 10px 0px 0px;
    }

    .caseListSidebarWrapper {
        display: none;
    }

    .caseListLogoContainer {
        grid-template-columns: 1fr auto;
    }

    .caseListLogo {

    }

    .caseListDrawerCloseButton {
        display: initial;
        height: 35px;
        width: 35px;
        background-color: var(--new-secondary-background-color);
        border: 0px;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        position: relative;
    }

    .caseListDrawerCloseButton::before, .caseListDrawerCloseButton::after {
        position: absolute;
        content: "";
        height: 25px;
        width: 2px;
        border-radius: 2px;
        background-color: var(--new-primary-color);
    }
    
    .caseListDrawerCloseButton::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    
    .caseListDrawerCloseButton::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    .caseListMobileNavButtonContainer {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 10px;
        margin: 20px 0px;
    }

    .caseListMobileNavButton {
        height: 40px;
        width: 100%;
        background-color: var(--new-secondary-background-color);
        color: var(--new-text-color);
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;
        justify-items: center;
        border: 0px;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .caseListDropdownWrapper {

    }

}

.caseListSidebarLabel {
    display: block;
    color: var(--new-secondary-off-text-color) !important;
    /* margin-left: 2px; */
}

.caseListDropdown {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    padding-left: 5px;
    width: 100%;
    height: 40px;
    color: var(--new-text-color);
    border: 0px;
}

.caseListSidebarHeader {
    font-size: 24px;
    font-weight: bold;
    color: var(--new-secondary-text-color);
    margin: 10px 0px;
}

.caseListSidebarSectionBreak {
    height: 5px;
    width: 100%;
}

.caseListSidebarCategoryWrapper {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 12px;
    align-items: center;
    margin-bottom: 5px;
    font-size: 15px;
    cursor: pointer;
}

.caseListSidebarCategoryWrapper:nth-last-child(1) {
    margin-bottom: 0px;
}

.caseListSidebarCategoryIcon {
    height: 25px;
    width: 25px;
}

.checkboxContainer {
    justify-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
}

.checkboxCheck {
    color: var(--new-primary-color);
}

.caseListSidebarCategoryIcon {
    color: var(--new-secondary-color);
    font-size: 32px;
}

.caseListSidebarText {
    color: var(--new-secondary-off-text-color);
    margin: 0px;
}

.caseListSidebarSubtext {
    font-size: 14px;
    color: var(--new-secondary-off-text-color);
    margin: 0px;
}

.caseListSidebarTagWrapper {
    display: grid;
    grid-template-columns: 50px 1fr;
    margin-top: 10px;
    align-items: center;
    min-height: 25px;
    font-size: 15px;
    cursor: pointer;
}

.sidebarToggleDisabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: initial;
}

.caseListSidebarTagWrapper:nth-last-child(1) {
    padding-bottom: 20px;
}

.caseListSidebarAddTagButton {
    width: 100%;
    height: 32px;
    font-size: 14px;
    margin-top: 10px;
}




.caseListLogoText {
    font-size: 36px;
    margin: 0px;
    font-weight: 300;
    margin-bottom: 10px;
    color: var(--new-secondary-color);
}

.caseListLogoTextNormal {
    color: white;
}

.caseListSearchInput {
    height: 40px;
    box-sizing: border-box;
    padding-left: 5px;
    border-radius: 3px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--new-secondary-background-color);
    color: var(--new-text-color);
}

@media(max-width: 950px) {
    .caseListSearchInput {
        /* font-size: 16px; */
        /* transform: scaleY(0.8); */
    }
}

.caseListMyExamsViewButton {
    height: 40px;
    width: 100%;
    border-radius: 3px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--new-secondary-color);
    color: white;
    font-size: 13px;
    /* font-weight: 500; */
    margin-top: 20px;
    cursor: pointer;
}