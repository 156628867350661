

.multipleChoiceWrapper {
    margin-bottom: 50px;
}

.multipleChoiceQuestionContainer {
    background-color: #F5F7F8;
    padding: 15px 10px;
    margin-bottom: 20px;
}

.multipleChoiceQuestionText {
    margin: 0px;
    font-size: 17px;
    white-space: pre-wrap;
    line-height: 1.8;
}

.multipleChoiceAnswerWrapper {
    display: grid;
    grid-template-columns: 30px 1fr;
    /* column-gap: 20px; */
    cursor: pointer;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    border-color: #3D505A;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    padding: 0px;
}

.multipleChoiceAnswerWrapper:hover {
    border-color: #eab326;
}

.multipleChoiceAnswerLetter {

}

.multipleChoiceAnswerLetterText {
    margin: 0px;
    font-size: 16px;
}

.multipleChoiceAnswer {
    border: 3px solid;
    border-color: inherit;
    padding: 13px 8px;
    width: calc(100% - 22px);
    transition-duration: 200ms;
    position: relative;
    text-align: start;
    font-size: 16px;
}

.selectedMultipleChoiceAnswer {
    background-color: #f1d176;
    border-color: #eab326;
    outline: 2px solid white;
}

.selectedMultipleChoiceAnswer:before {
    content: '';
    position: absolute;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border: 2px solid white;
    top: 0px;
    left: 0px;
    z-index: 10;
}

.multipleChoiceAnswerText {
    margin: 0px;
}

.crossedOutText {
    text-decoration: line-through;
}

.multipleChoiceExtractWrapper {
    background-color: rgb(235, 235, 235);
    padding: 10px;
}

.multipleChoiceExtractContainer {
    border: 1px solid black;
    width: 90%;
}

.multipleChoiceExtractHeader {
    font-weight: bold;
    margin: 0px;
    border-bottom: 1px solid black;
    padding: 5px;
}

.multipleChoiceExtractBody {
    margin: 0px;
    padding: 5px;
    line-height: 2;
}
