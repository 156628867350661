.reportCardFirstCaseAttemptCheckboxWrapper {
    width: fit-content;
    align-items: center;
    margin-bottom: 20px;
}

.reportCardCheckboxWrapper {
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    vertical-align: top;
    cursor: pointer;
}

.reportCardCheckbox {
    height: 16px;
    width: 16px;
    cursor: pointer;
    border-radius: 1px;
    border: 1px solid #CCCCCC;
    background-color: #EEEEEE;
}

.checkedCheckbox {
    position: relative;
}

.checkedCheckbox::before, .checkedCheckbox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #707070;
    height: 12px;
    width: 1px;
}

.checkedCheckbox::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.checkedCheckbox::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.reportCardCheckbox:hover {
    background-color: #E5E5E5;
}

.reportCardCheckboxLabel {
    margin: 0px 15px 0px 5px;
    font-size: 14px;
    pointer-events: none;
}

.reportCardFirstCaseAttemptGraphWrapper {
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 10px;
}

.reportCardGraphLegendWrapper {
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
}

.reportCardGraphLegend {
    height: 15px;
    width: 15px;
    border: 1px solid #CCCCCC;
    border-radius: 1px;
}

.reportCardGraphLegendText {
    margin: 0px 15px 0px 5px;
    font-size: 14px;
}

.reportCardFirstCaseAttemptGraphContainer {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    /* min-height: 230px; */
}

.reportCardFirstCaseAttemptGraphSectionWrapper {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    padding-left: 10px;
}

.hideBarGraphContainer {
    display: none;
}

.reportCardFirstCaseAttemptGraphSectionTitle {

}

.reportCardFirstCaseAttemptGraphElementWrapper {
    height: 180px;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: end;
    width: fit-content;
    vertical-align: top;
}

.reportCardFirstCaseAttemptTitleValueWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 20px;
}

@media(max-width: 600px) {
    .reportCardFirstCaseAttemptTitleValueWrapper {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}