
.exitExamPopupPageContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.exitExamPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.exitExamPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 550px;
    /* height: 300px; */
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.50);
    z-index: 2;
}

.exitExamPopupText {
    margin: 0px 20px !important;
    padding: 20px 0px 10px 0px !important;
    text-align: left;
}
