.breakPopup {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    background-color: #0283BE;
    display: flex;
}

.breakPopupContainer {
    display: grid;
    grid-template-rows: 100px / 100px;
    place-items: center;
    grid-row-gap: 5px;
    margin: auto;
    width: 90%;
    height: 80%;
    max-width: 500px;
    max-height: 125px;
}

.buttonContainer {
    margin: 0;
    z-index: 1;
}

.resumeBtn {
    background: transparent;
    color: white;
    min-width: 100px;
    height: 60px;
    border: 1px solid white;
    border-radius: 4px;
    box-shadow: 0 0 2px white;
    outline: none;
}

.resumeIcon {
    padding-top: 0px;
    padding-bottom: 2px;
    height: 100%;
    max-height: 23px;
}

.buttonTitle {
    padding-top: 0px;
    display: block;
    font-size: 12px;
}

.breakTextContainer {
    z-index: 2;
    margin: 0;
    width: 100%;
    text-align: center;
}

.breakText {
    color: white;
    font-size: 12px;
}