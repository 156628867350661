

.newGradingPageWrapper {
    background-color: var(--new-primary-background-color);
    padding-bottom: 40px;
    min-height: calc(100% - 40px);
    position: relative;
}

.newGradingNavbar {
    height: 45px;
    width: calc(100% - 30px);
    background-color: var(--new-tertiary-background-color);
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    column-gap: 10px;
    padding: 10px 15px;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
}

.newGradingLogo {
    height: 35px;
}

@media(max-width: 750px) {
    .newGradingPageWrapper {
        padding-bottom: 10px;
        min-height: calc(100% - 10px);
    }
}

.newGradingLoaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    display: grid;
    align-items: center;
    justify-items: center;
}

.newGradingLoaderText {
    color: var(--new-text-color);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

/* End Navbar */
/* Begin Back Button Wrapper */

.newGradingBackWrapper {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 10px;
    height: 60px;
    align-items: center;
    margin: 20px 40px;
}

.newGradingBackButton {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    justify-items: center;
    height: 40px;
    width: 200px;
}

.newGradingBackIcon {
    color: white;
    font-size: 16px;
}

.newGradingCheckboxContainer {
    height: 40px;
    display: grid;
    width: 175px;
    column-gap: 10px;
    align-items: center;
    justify-items: center;
    background-color: var(--new-secondary-background-color);
    padding: 5px 10px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;
}

.highYieldLoadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: grid;
    align-items: center;
    justify-items: center;
}

.highYieldMoreInfoButton {
    color: var(--new-primary-color);
    font-size: 20px;
    padding: 10px;
    padding-left: 0px;
    padding-right: 5px;
}

.newGradingCheckbox {
    height: 25px;
    width: 25px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    align-items: center;
    justify-items: center;
}

.newGradingCheckboxIcon {
    color: var(--new-primary-color);
    font-size: 14px;
}

.newGradingCheckboxText {
    font-size: 14px;
    margin: 0px;
    color: var(--new-text-color);
}

@media(max-width: 750px) {
    .newGradingBackWrapper {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        row-gap: 10px;
        height: fit-content;
        margin: 10px;
    }

    .newGradingBackButton {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .newGradingCheckboxContainer {
        width: calc(100% - 20px);
    }
}

/* End Back Button Wrapper */
/* Begin Title Container */

.newGradingCaseTitleContainer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 20px;
    padding: 15px;
    background-color: var(--new-secondary-background-color);
    margin: 5px 40px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.newGradingCaseNumberContainer {
    height: 60px;
    min-width: 40px;
    padding: 0px 10px;
    width: fit-content;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: var(--new-secondary-color);
    border-radius: 3px;
    align-self: center;
}

.newGradingCaseNumber {
    color: white;
    font-size: 36px;
    font-weight: 600;
    margin: 0px;
}

.newGradingCaseTitleWrapper {
    display: grid;
    grid-auto-flow: row;
    row-gap: 5px;
    align-self: center;
}

.newGradingCaseTitle {
    margin: 0px;
    font-weight: 600;
    color: var(--new-text-color);
}

.newGradingCaseCompletionDate {
    margin: 0px;
    font-weight: 500;
    color: var(--new-off-text-color);
    font-size: 16px;
}

.newGradingViewPreviousButton {
    height: 40px;
    align-self: center;
    padding: 0px 20px;
}

@media(max-width: 750px) {
    .newGradingCaseTitleContainer {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        row-gap: 10px;
        margin: 10px;
    }

    .newGradingCaseNumberContainer {
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .newGradingCaseTitle {
        font-size: 20px;
    }

    .newGradingCaseCompletionDate {
        font-size: 14px;
    }

    .newGradingViewPreviousButton {
        max-width: 200px;
        font-size: 12px;
    }
}

/* End Title Container */
/* Begin Details */

.newGradingCaseDetailsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 40px;
    column-gap: 15px;
    padding-bottom: 0px;
}

.newGradingCaseDetailContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
    align-items: center;
    background-color: var(--new-secondary-background-color);
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.newGradingCaseDetailIconContainer {
    height: 60px;
    width: 60px;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: var(--new-primary-color);
    border-radius: 3px;
    grid-row-start: 1;
    grid-row-end: 3;
}

.newGradingCaseDetailIcon {
    color: white;
    font-size: 28px;
}

.newGradingCaseDetailTitle {
    margin: 0px;
    font-size: 12px;
    color: var(--new-off-text-color);
}

.newGradingCaseDetailValue {
    margin: 0px;
    font-size: 26px;
    color: var(--new-text-color);
}

@media(max-width: 750px) {
    .newGradingCaseDetailsWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 10px;
        padding: 0px;
        margin: 10px;
    }

    .newGradingCaseDetailIconContainer {
        height: 40px;
        width: 40px;
    }

    .newGradingCaseDetailIcon {
        font-size: 20px;
    }

    .newGradingCaseDetailTitle {
        font-size: 10px;
    }

    .newGradingCaseDetailValue {
        font-size: 20px;
    }

}

/* End Details */
/* Begin Feedback */

/* Begin New Feedback For OET */

.gradingFeedbackSectionContainer {
    display: grid;
    margin: 5px 40px;
    margin-top: 15px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradingFeedbackSectionHeaderWrapper {
    display: grid;
    grid-template-columns: 43px 1fr auto;
    column-gap: 15px;
    margin: 15px;
    margin-bottom: 0px;
}

.gradingFeedbackSectionExpandButton {
    background-color: var(--new-primary-color);
    color: var(--new-secondary-background-color);
    border: 0px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradingFeedbackSectionExpandButton::before, .gradingFeedbackSectionExpandButton::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 3px;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 3px;
}

.gradingFeedbackSectionExpandButtonOpen::before {
    transform: translate(-50%, -50%) rotate(90deg);
}

.gradingFeedbackSectionHeaderInnerWrapper {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 5px;
}

.gradingFeedbackSectionWritingFeedbackButton {
    height: 40px;
    width: 200px;
    background-color: var(--new-primary-color);
    color: var(--new-secondary-background-color);
    border: 0px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradingFeedbackSectionHeader {
    margin: 0px;
    font-weight: 600;
    font-size: 22px;
    color: var(--new-text-color);
}

.gradingFeedbackSectionSubheader {
    margin: 0px;
    font-size: 14px;
    color: var(--new-off-text-color);
}

.gradingFeedbackSectionBodyContainer {
    margin: 15px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.gradingFeedbackInfoComponentContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin: 15px;
}

.gradingFeedbackInfoText {
    margin: 0px;
    white-space: pre-wrap;
    line-height: 1.2;
}

.gradingFeedbackInfoComponentText {
    margin: 0px;
    color: var(--new-text-color);
}

.gradingFeedbackAudioComponentContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin: 15px;
}

.gradingFeedbackAudioComponentTitle {
    margin: 0px;
    color: var(--new-text-color);
}

.gradingFeedbackAudioComponentPlayerWrapper {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    margin-top: 10px;
    align-items: center;
}

.gradingFeedbackAudioComponentPlayButton {
    width: 60px;
    height: 60px;
    cursor: pointer;
    background-color: var(--new-primary-color);
    color: white;
    border: 0px;
    border-radius: 3px;
}

.gradingFeedbackAudioComponentPlayIcon {
    font-size: 24px;
    color: white;
}

.gradingFeedbackAudioComponentTime {
    margin: 0px 10px;
    color: var(--new-off-text-color);
}

.gradingFeedbackAudioComponentProgressBar {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: var(--new-primary-background-color);
    /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.5); */
    outline: none;
    /* opacity: 0.7; */
    -webkit-transition: .2s;
    transition: opacity .2s;
    cursor: pointer;
}

.gradingFeedbackAudioComponentProgressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--new-primary-color);
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.gradingFeedbackAudioComponentProgressBar::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--new-primary-color);
    cursor: pointer;
}

.gradingFeedbackPDFComponentContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    margin: 15px;
}

.gradingFeedbackPDFText {
    margin: 0px;
    color: var(--new-text-color);
    font-weight: bold;
    margin-left: 10px;
}

.gradingFeedbackInputQuestionContainer {
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 15px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: 50px auto;
}

.gradingFeedbackInputQuestionNumberWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px 0px 0px 3px;
}

.gradingFeedbackInputQuestionNumber {
    margin: 0px;
    font-size: 32px;
    color: white;
}

.gradingFeedbackInputQuestionContentWrapper {
    padding: 15px;
    display: grid;
    row-gap: 10px;
}

.gradingFeedbackInputQuestionTitle {
    margin: 0px;
    font-size: 17px;
}

.gradingFeedbackInputQuestionText {
    margin: 0px;
    font-size: 15px;
}

.gradingFeedbackMultipleChoiceQuestionContainer {
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 15px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: 50px auto;
}

.gradingFeedbackMultipleChoiceQuestionNumberWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px 0px 0px 3px;
}

.gradingFeedbackMultipleChoiceQuestionNumber {
    margin: 0px;
    font-size: 32px;
    color: white;
}

.gradingFeedbackMultipleChoiceQuestionContentWrapper {
    padding: 15px;
    display: grid;
    row-gap: 10px;
}

.gradingFeedbackMultipleChoiceQuestionTitle {
    margin: 0px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
}

.gradingFeedbackMultipleChoiceQuestionAnswerContainer {
    display: grid;
    grid-template-columns: 40px auto;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    background-color: #F5F5F5;
}

.gradingFeedbackMultipleChoiceQuestionCheckboxWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: #6A6A6A;
    border-radius: 3px 0px 0px 3px;
}

.gradingFeedbackMultipleChoiceQuestionCheckbox {
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.gradingFeedbackMultipleChoiceQuestionCheckboxIcon {
    font-size: 14px;
}

.gradingFeedbackMultipleChoiceQuestionAnswerWrapper {
    display: grid;
    row-gap: 5px;
    padding: 15px;
}

.gradingFeedbackMultipleChoiceQuestionAnswerText {
    margin: 0px;
    font-size: 16px;
    color: var(--new-text-color);
}

.gradingFeedbackMultipleChoiceQuestionAnswerSubtext {
    margin: 0px;
    font-size: 12px;
    color: var(--new-off-text-color);
}

@media(max-width: 750px) {
    .gradingFeedbackSectionContainer {
        margin: 10px;
    }

    .gradingFeedbackSectionExpandButton {
        height: 40px;
        width: 40px;
    }

    .gradingFeedbackSectionExpandButton::before, .gradingFeedbackSectionExpandButton::after {
        width: 20px;
    }

    .gradingFeedbackSectionHeaderWrapper {
        column-gap: 10px;
        grid-template-columns: 40px auto;
        /* margin: 10px; */
        /* margin-bottom: 0px; */
    }

    .gradingFeedbackSectionWritingFeedbackButton {
        margin-top: 15px;
    }

    .gradingFeedbackSectionHeader {
        font-size: 18px;
    }

    .gradingFeedbackSectionSubheader {
        font-size: 12px;
    }

    .gradingFeedbackSectionBodyContainer {
        /* margin: 10px; */
    }

    .gradingFeedbackInfoText {
        font-size: 14px;
    }

    .gradingFeedbackAudioComponentTitle {
        font-size: 16px;
    }

    .gradingFeedbackAudioComponentPlayButton {
        height: 40px;
        width: 40px;
    }

    .gradingFeedbackAudioComponentPlayIcon {
        font-size: 20px;
    }

    .gradingFeedbackAudioComponentTime {
        font-size: 14px;
    }

    .gradingFeedbackPDFText {
        font-size: 14px;
        text-align: left;
    }

    .gradingFeedbackInputQuestionTitle {
        font-size: 16px;
    }

    .gradingFeedbackInputQuestionText {
        font-size: 14px;
    }

    .gradingFeedbackMultipleChoiceQuestionTitle {
        font-size: 16px;
    }

    .gradingFeedbackMultipleChoiceQuestionAnswerText {
        font-size: 14px;
    }

    .gradingFeedbackMultipleChoicePDFText {
        font-size: 14px;
        text-align: left;
        margin-left: 5px;
    }
}

/* End OET Feedback */

.newGradingFeedbackItemContainer {
    background-color: var(--new-secondary-background-color);
    margin: 15px 40px;
    margin-top: 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
}

.newGradingFeedbackItemTitle {
    margin: 0px;
    color: var(--new-text-color);
    font-weight: 600;
}

.newGradingFeedbackItemValue {
    margin: 0px;
    font-size: 14px;
    margin-top: 4px;
    font-weight: 500;
    color: var(--new-off-text-color);
}

.newGradingFeedbackItemContentWrapper {
    background-color: var(--new-primary-background-color);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin-top: 20px;
    border-radius: 3px;
}

.newGradingFeedbackAnswerWrapper {
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}

.newGradingFeedbackAnswerWrapper:nth-last-child(1) {
    margin-bottom: 0px;
}

.newGradingFeedbackAnswerIncorrect {
    background-color: #FFDDDD;
    color: #411414;
}

.newGradingFeedbackAnswerCorrect {
    background-color: #E2FFDD;
    color: #175317;
}

.newGradingFeedbackAnswerNeutral {
    background-color: #d1ecf1;
    color: #203438;
}

.newGradingFeedbackAnswerTitle {
    margin: 0px;
    font-size: 18px;
    color: inherit;
}

.newGradingFeedbackAnswerItemList {
    margin: 0px;
}

.newGradingFeedbackAnswerItem {
    margin-bottom: 3px;
}

.newGradingFeedbackReason {
    margin: 0px;
    margin-top: 15px;
}

@media(max-width: 750px) {
    .newGradingFeedbackItemContainer {
        margin: 10px;
    }

    .newGradingFeedbackItemTitle {
        font-size: 20px;
    }

    .newGradingFeedbackItemValue {
        font-size: 14px;
    }

    .newGradingFeedbackItemContentWrapper {
        padding: 10px;
    }

    .newGradingFeedbackAnswerWrapper {
        padding: 10px;
    }

    .newGradingFeedbackAnswerTitle {
        font-size: 16px;
    }

    .newGradingFeedbackAnswerItemList {
        margin: 0px;
        padding-left: 25px;
    }

    .newGradingFeedbackAnswerItem {
    }

    .newGradingFeedbackReason {
        font-size: 13px;
    }
}

/* End Feedback */
/* Begin CaseSummary */

.newGradingCaseSummaryContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 5px 40px;
    padding: 20px;
}

.newGradingCaseSummaryTitle {
    margin: 0px;
    font-weight: 600;
    color: var(--new-text-color);
}

.newGradingCaseSummaryText {
    margin: 0px;
    margin-top: 10px;
    color: var(--new-text-color);
	white-space: pre-wrap;
}

@media(max-width: 750px) {
    .newGradingCaseSummaryContainer {
        padding: 20px;
        margin: 10px;
    }

    .newGradingCaseSummaryTitle {
        font-size: 22px;
    }

    .newGradingCaseSummaryText {
        font-size: 14px;
    }
}

/* End Case Summary */