

.infoContainer {
    background-color: #F5F7F8;
    padding: 10px;
    margin-bottom: 1px;
}

.infoText {
    margin: 0px;
    white-space: pre-wrap;
    line-height: 1.8;
}

.centeredText {
    text-align: center;
    display: inline-block;
    width: 100%;
    /* vertical-align: start; */
}