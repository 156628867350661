.finishSectionPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
}

.finishSectionPopupBackdrop {
    background: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: backdropAnimation 0.3s ease-in-out;
}

@keyframes backdropAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.finishSectionPopupModal {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.50);
    border-radius: 4px;
    width: 90%;
    max-width: 450px;
    height: fit-content;
    overflow: hidden;
    z-index: 2;
}

.finishSectionWarning {
    animation: modalAnimation 0.3s ease-in-out forwards;
}

.finishSectionConfirmation {
    animation: modalAnimation2 0.3s ease-in-out forwards;
}

@keyframes modalAnimation {
    from {
        transform: translate(-50%, -100%);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

@keyframes modalAnimation2 {
    from {
        transform: translate(-50%, -100%);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

.finishSectionPopupModalHeader {
    background: #0283BE;
    padding: 7px;
}

.finishSectionPopupModalHeader p {
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin: 0;
}

.clockIconPopup {
    color: #F4AD23;
    padding: 30px 20px 30px 20px;
    font-size: 48px;
}

.finishSectionPopupModalBody {
    display: grid;
    justify-items: center;
    align-items: center;
    position: relative;
    text-align: center;
}

.finishSectionPopupModalBody p {
    font-size: 16px;
    padding-top: 0px;
    margin-top: 0;
    margin-left: 12%;
    margin-right: 12%;
}

.finishSectionPopupModalFooter {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: 1fr 1fr;
    position: relative;
    text-align: center;
    margin: 15px auto 10px auto;
    max-width: 90%;
}

.finishSectionPopupConfirmButtonWrapper {
    width: fit-content;
    grid-template-rows: 1fr;
    grid-template-columns: auto auto;
    column-gap: 10px;
}

.finishSectionPopupModalFooter button {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 20px auto;
    column-gap: 5px;
    margin-bottom: 5px;
    padding: 7px;
    padding-right: 10px;
    font-size: 16px;
    background-color: #1A517B;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

/* .finishSectionPopupModalFooter p {
    grid-column-start: 2;
} */

.buttonIcon {
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

/* @keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}

@media only screen and (max-width: 400px) {
    .finishSectionPopupModalFooter button {
        width: 90%;
    }
} */