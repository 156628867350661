.simulationHeaderContainer {

}

.simulationHeaderWrapper {
    background-color: var(--primary-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 8px 10px;
}

.simulationHeaderText {
    margin: 0px;
    color: white;
}

.simulationHeaderTimerWrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 25px auto;
    column-gap: 10px;
    width: fit-content;
    justify-self: center;
}

.clockIcon {
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;
    justify-self: center;
    color: white;
    height: 100%;
    max-height: 25px;
}

.simulationHeaderButton {
    background-color: var(--primary-button-color);
    color: white;
    border: 0px;
    justify-self: end;
    align-self: center;
    height: 100%;
    max-width: 150px;
    width: 100%;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    max-height: 38px;
}

@media(max-width: 800px) {
    .simulationHeaderWrapper {
        grid-template-columns: 1fr 150px;
        grid-template-rows: auto auto;
        row-gap: 5px;
    }

    .simulationHeaderTimerWrapper {
        grid-row: 2;
        grid-template-rows: auto auto;
    }

    .simulationHeaderButton {
        grid-row-start: 1;
        grid-row-end: 3;
    }
}

.simulationSubheaderWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--secondary-color);
    padding: 2px 5px;
}

.simulationSubheaderWrapper > .simulationHeaderText:nth-child(2) {
    justify-self: end;
}

.buttonDisabled {
    opacity: 0.5;
    pointer-events: none;
}
