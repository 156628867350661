

.listeningInputQuestionsWrapper {
    margin-bottom: 50px;
}

.listeningInputQuestionSectionHeader {

}

.listeningInputQuestionBoldText {
    font-size: 16px;
    height: fit-content;
}

.listeningInputQuestionContainer {
    display: grid;
    grid-template-columns: 150px 1fr;
    column-gap: 10px;
    align-items: center;
}

.listeningInputQuestionContainer > .listeningInputQuestionBoldText {
    justify-self: end;
}

.listeningInputStatementContainer {
    margin: 18px 0px;
    height: fit-content;
}

.listeningInputStatementIndented {

}

.listeningInputStatementText {
    display: inline;
    /* margin: 0px; */
}

.listeningQuestionBulletPoint::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: relative;
    top: -3px;
    margin-right: 5px;
    display: inline-block;
    background-color: rgb(53, 53, 53);
}

.listeningQuestionSubBulletPoint {
    margin-left: 35px;
}

.listeningQuestionSubBulletPoint::before {
    content: '';
    width: 4px;
    height: 2px;
    position: relative;
    top: -3px;
    margin-right: 5px;
    display: inline-block;
    background-color: rgb(53, 53, 53);
}

.listeningInputStatementInput {
    box-sizing: border-box;
    display: inline-block;
    margin: 0px 5px;
    border: 3px solid #9ea7ac;
    border-radius: 3px;
    height: 35px;
    width: 100%;
    max-width: 328px;
    /* position: relative; */
    /* top: -7px; */
    /* margin-bottom: 7px; */
    padding-left: 5px;
    font-size: 16px;
    transition-duration: 300ms;
}

.listeningInputStatementInput:focus {
    border-radius: 0px;
    outline: none;
    border-color: #549cc9;
    box-shadow: 0px 0px 8px #659ce0;
}