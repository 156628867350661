
.outOfTimePopupPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.outOfTimePopupBackdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.45);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: backdropAnimation 0.3s ease-in-out;
}

.outOfTimePopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.50);
    border-radius: 4px;
    width: 90%;
    max-width: 450px;
    height: fit-content;
    overflow: hidden;
    z-index: 2;
    animation: modalAnimation 0.3s ease-in-out forwards;
}

.outOfTimePopupHeader {
    background: #0283BE;
    padding: 7px;
}

.outOfTimePopupHeaderText {
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin: 0;
}

.outOfTimePopupText {
    padding: 20px;
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.outOfTimePopupButton {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 20px auto;
    column-gap: 5px;
    margin-bottom: 5px;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    background-color: #1A517B;
    color: white;
    border: none;
    margin: auto;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 20px;
}
