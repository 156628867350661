

.audoTestPopupPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
}

.audioTestPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
}

.audioTestPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    width: 95%;
    max-width: 500px;
    background-color: white;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.audioTestPopupHeaderContainer {
    background-color: #334F75;
    height: 50px;
    padding-left: 10px;
    display: grid;
    align-items: center;
}

.audioTestPopupHeaderText {
    margin: 0px;
    color: white;
}

.audioTestPopupTestContainer {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    width: fit-content;
    margin: 20px auto;
    align-items: center;
}

.audioTestPopupTestSuccessIcon, .audioTestPopupTestFailedIcon {
    height: 30px;
    width: 30px;
}

.audioTestPopupTestSuccessIcon {
    color: #15AD44;
}

.audioTestPopupTestFailedIcon {
    color: #FA3B3B;
}

.audioTestPopupTestText {
    margin: 0px;
}

.audioTestPopupInfoText {
    text-align: center;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
}

.audioTestPopupButtonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    width: calc(100% - 20px);
    margin: 10px auto;
    align-items: center;
}

.audioTestPopupConfirmButton, .audioTestPopupCancelButton {
    cursor: pointer;
    height: 40px;
    border-radius: 5px;
    border: 0px;
}

.audioTestPopupConfirmButton {
    background-color: #334F75;
    color: white;
}

.audioTestPopupCancelButton {

}
