

.caseListSectionContainer {
    display: grid;
    grid-template-columns: 40px 1fr;
    height: fit-content;
    /* height: 75px; */
    min-height: 65px;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    background-color: var(--new-secondary-background-color);
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition-duration: 150ms;
    cursor: pointer;
    position: relative;
}

.caseListSectionContainer:nth-last-child(1) {
    margin-bottom: 0px;
}

.caseListSectionContainer:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
}

@media(min-width: 800px) {
    .caseListSectionContainer:hover {
        transform: scale(1.005) !important;
        z-index: 5;
    }
}

.caseListSectionCheckboxWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    transition-duration: 150ms;
}

.caseListSectionCheckboxSelected {
    background-color: var(--new-primary-color);
}

.caseListSectionCheckbox {
    height: 17px;
    width: 17px;
    margin: 0px;
    padding: 0px;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) inset;
    border: 0px;
    cursor: pointer;
}

.caseListSectionCheckboxIcon {
    font-size: 10px;
    color: var(--new-text-color);
}

.caseListSectionContentWrapper {
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    column-gap: 10px;
    grid-template-rows: 1fr auto;
    row-gap: 5px;
    height: fit-content;
    align-self: center;
    padding: 10px;
}

.caseListSectionDetailsWrapper {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 40px 1fr;
    height: fit-content;
    align-self: center;
}

.caseListSectionDetailsIcon {
    grid-row-start: 1;
    grid-row-end: 3;
    color: var(--new-primary-color);
    font-size: 24px;
    align-self: center;
    justify-self: center;
}

.caseListSectionDetailsTitle {
    margin: 0px;
    font-size: 17px;
    font-weight: 600;
    color: var(--new-text-color);
}

.caseListSectionDetailsSubtitle {
    margin: 0px;
    font-size: 12px;
    opacity: 0.8;
    color: var(--new-text-color);
}

.caseListSectionCompletionText {
    margin: 0px;
    font-size: 13px;
    grid-row: 2;
    color: var(--new-text-color);
}

.caseListSectionViewGradesButton, .caseListSectionReviewLaterButton {
    grid-row-start: 1;
    grid-row-end: 3;
    background-color: var(--new-secondary-background-color);
    color: var(--new-text-color);
    cursor: pointer;
    border-radius: 3px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    height: 35px;
    align-self: center;
    padding: 0px;
    font-size: 13px;
}

.caseListSectionViewGradesButton {
    grid-column: 2;
}

.caseListSectionReviewLaterButton {
    grid-column: 3;
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
}

.caseListSectionReviewLaterCheckboxWrapper {
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.caseListSectionReviewLaterCheckbox {
    background-color: var(--new-secondary-background-color);
    height: 15px;
    width: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.caseListSectionReviewLaterCheckboxIcon {
    color: var(--new-primary-color);
    font-size: 10px;
}

.caseListSectionMobileMoreOptionsButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
    width: 25px;
    background-color: transparent;
    border: 0px;
    display: none;
    cursor: pointer;
}

.caseListSectionMobileMoreOptionsIcon {
    font-size: 20px;
    color: var(--new-text-color);
}

@media(max-width: 700px) {
    .caseListSectionContentWrapper {
        grid-template-columns: 1fr;
    }

    .caseListSectionViewGradesButton, .caseListSectionReviewLaterButton {
        display: none;
    }

    .caseListSectionMobileMoreOptionsButton {
        display: initial;
    }
}