
.audioRecorderWrapper {
    border-radius: 5px;
    height: fit-content;
    padding: 20px 0px;
    background-color: #334F75;
    width: 100%;
    margin-top: 50px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
}

.audioRecordingButton {
    height: 40px;
    width: 100%;
    max-width: 200px;
    background-color: white;
    color: black;
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
    margin: 0px 50px;
}

.audioRecordingTextWrapper {
    display: grid;
    row-gap: 5px;
}

.audioRecordingTitle {
    margin: 0px;
    color: white;
}

.audioRecordingText {
    margin: 0px;
    color: white;
}

.visualizerContainer {
    
}
  
.visualizerContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.visualizerContainer > div {
    display: inline-block;
    width: 3px;
    height: 100px;
    margin: 0 7px;
    background: black;
    transform: scaleY( .5 );
    opacity: .25;
}

.audioVisualizerWrapper {
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: center;
    justify-items: center;
    width: 100%;
}

.audioVisualizerContainer {
    border-radius: 5px;
    /* width: 100%; */
    background-color: white;
    box-shadow: 0px 2px 4px inset rgba(0, 0, 0, 0.25);
    padding: 5px 20px;
}

.audioVisualizerText {
    margin-bottom: 0px;
    color: white;
}

@media(max-width: 900px) {
    .audioVisualizerWrapper {
        grid-row: 1;
    }

    .audioRecorderWrapper {
        grid-auto-flow: row;
        row-gap: 20px;
    }

    .visualizerContainer > div {
        margin: 0 5px;
        height: 80px;
    }
}

@media(max-width: 400px) {
    .visualizerContainer > div {
        margin: 0 3px;
        height: 60px;
    }
}