

.audioComponentContainer {
    display: grid;
    grid-template-rows: 100px 40px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #766d5f;
    background-color: #F5F5F5;
    margin: 50px 0px;
}

.audioAnimationWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
}

.audioAnimationContainer {
    height: 40px;
    width: 80px;
    /* background-color: #3f3f3f; */
}

.audioGif {
    height: 100%;
    width: 100%;
}

.audioControlsWrapper {
    background: #434343;
    display: grid;
    grid-template-columns: 40px 1fr;
}

.audioPlayButtonWrapper {
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.audioPlayButton {
    color: white;
    font-size: 18px;
}

.audioVolumeWrapper {
    display: grid;
    grid-template-columns: 40px 80px;
    align-items: center;
    justify-items: center;
    width: fit-content;
    justify-self: end;
    padding-right: 10px;
}

.audioVolumeButton {
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.audioVolumeSliderWrapper {

}

.audioVolumeSlider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    /* background: #292929; */
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.5);
    outline: none;
    /* opacity: 0.7; */
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.audioVolumeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.audioVolumeSlider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
}