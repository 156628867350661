

.caseListCaseContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    padding-top: 0px;
    position: relative;
    height: auto;
    padding-bottom: 40px;
    cursor: pointer;
    transition-duration: 150ms;
    color: var(--text-color);
}

.caseListCaseContainer:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.55);
}

@media(min-width: 800px) {
    .caseListCaseContainer:hover {
        transform: scale(1.02) !important;
        z-index: 5;
    }
}

.caseListCaseSelected {
    background-color: var(--case-selected-color) !important;
}

.caseListCompletedCaseMarkerWrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border-top-right-radius: 3px;
}

.caseListCompletedCaseMarkerWrapper:nth-child(2) {
    width: 40px;
    height: 40px;
}

.caseListCompletedCaseMarkerBlue, .caseListCompletedCaseMarkerBlueSelected {
    border: 30px solid var(--new-primary-color);
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-right-radius: 3px;
}

.caseListCompletedCaseMarkerBlue::after, .caseListCompletedCaseMarkerBlueSelected::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    border: 23px solid var(--new-secondary-background-color);
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-right-radius: 3px;
    transition-duration: 150ms;
}

.caseListCompletedCaseMarkerBlueSelected::after {
    border: 23px solid var(--case-selected-color);
}

.caseListCompletedCaseMarkerOrange, .caseListCompletedCaseMarkerOrangeSelected {
    border: 20px solid var(--new-secondary-color);
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-right-radius: 3px;
}

.caseListCompletedCaseMarkerOrange::after, .caseListCompletedCaseMarkerOrangeSelected::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    border: 14px solid var(--new-secondary-background-color);
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-right-radius: 3px;
    transition-duration: 150ms;
}

.caseListCompletedCaseMarkerOrangeSelected::after {
    border: 14px solid var(--case-selected-color);
}

.caseListCaseHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
    padding-top: 5px;
    grid-template-rows: auto auto;
    border-bottom: 1px solid #707070;
    padding-bottom: 5px;
}

.caseListCaseId {
    margin: 0px;
    font-size: 36px;
    color: var(--new-primary-color);
    display: inline-block;
    transition-duration: 150ms;
    grid-row-start: 1;
    grid-row-end: 3;
}

.caseListCaseTitle {
    display: inline-block;
    font-size: 18px;
    margin: 0px;
    color: var(--new-case-text-color);
    margin-right: 40px;
}

.caseListCaseMeta {
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.7;
    margin-right: 15px;
    color: var(--new-case-text-color);
}

.caseListCaseBodyWrapper {
    padding-top: 10px;
    padding-bottom: 5px;
}

.caseListCaseSectionWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.caseListCaseSectionIcon {
    font-size: 18px;
    color: var(--new-primary-color);
}

.caseListCaseSectionText {
    margin: 0px;
    font-size: 12px;
    color: var(--new-text-color);
}


.caseListCaseFooterContainer {
    background-color: var(--new-primary-background-color);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 5px;
    border-radius: 0px 0px 3px 3px;
    color: var(--new-text-color);
    transition-duration: 150ms;
}

.caseListCaseFooterSelected {
    background-color: var(--new-primary-color);
    color: white;
}

.caseListCaseFooterReviewLaterContainer {
    align-self: center;
    display: grid;
    width: fit-content;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-items: center;
}

.caseListCaseReviewLaterText {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    /* color: var(--new-text-color); */
    color: var(--new-tertiary-off-text-color);
}

.caseListCaseReviewLaterTextSelected {
    color: white;
}

.caseListCaseButton {
    height: 28px;
    padding: 0px 20px;
    font-size: 12px;
    align-self: center;
}