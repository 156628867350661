

.simulationWrapper {
    height: 100%;
    /* display: grid; */
}

.simulationPageContainer {
    max-width: 1024px;
    margin: auto;
    background-color: white;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.buttonDisabled {
    opacity: 0.5;
    pointer-events: none;
}

/* Begin simulation body classes */
.simulationBodyWrapper {
    height: 100%;
    padding: 5px;
    display: grid;
    grid-template-columns: auto 1fr;
    overflow: hidden;
}

.simulationNavigationWrapper {
    padding: 0px 15px;
    padding-left: 10px;
    position: sticky;
    top: 0px;
    height: fit-content;
}

.simulationNavigationButton {
    width: 45px;
    background-color: var(--secondary-color);
    color: white;
    border: 0px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    display: block;
    margin-bottom: 5px;
    position: relative;
}

.simulationNavigationButtonSelected {
    border-radius: 5px 5px 5px 5px;
}

.simulationNavigationButtonSelected::after {
    content: "";
    /* Create a right facing triangle */
    border-style: solid;
    border-width: 11px;
    border-color: transparent;
    border-left-color: var(--secondary-color);
    position: absolute;
    top: 50%;
    transform: translateY(-51%);
    right: -21px;
    
}

.simulationContentWrapper {
    border: 1px solid #8c8c8c;
    border-radius: 5px;
    height: calc(100% - 10px);
    padding: 15px;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
}

.imageComponent {
    max-width: 500px;
    margin-top: 25px;
}

/* End simulation body classes */

.openPDFButtonContainer {
    
}

.openPDFButton {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0px;
    align-items: center;
}

.openPDFIconWrapper {
    border: 1px solid #f3b912;
    background: linear-gradient(to bottom, rgb(255, 255, 240)21%, rgb(255, 200, 120) 100%);
    padding: 4px 8px;
}

.openPDFButtonIcon {
    font-size: 18px;
}

.simulationBodyOverflowPopupContainer {
    position: absolute;
    height: fit-content;
    max-height: 60px;
    width: fit-content;
    bottom: 15px;
    right: 30px;
    background-color: green;
    border-radius: 3px;
    color: white;
    padding: 0px 15px;
    display: grid;
    grid-template-columns: 1fr auto;
    /* font-weight: bold; */
    font-size: 18px;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}

.simulationBodyOverflowPopupContainer::after {
    border-style: solid;
    border-width: 11px;
    border-color: transparent;
    border-left-color: green;
    content: "";
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}

.simulationBodyOverflowPopupIcon {

}