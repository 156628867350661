.gradingFeedbackAudioContainer {
    margin: 15px;
    padding: 15px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradingFeedbackAudioHeader {
    margin: 0px;
    margin-bottom: 20px;
}

.gradingFeedbackAudioModuleWrapper {
    display: grid;
    grid-template-columns: 50px auto 1fr auto;
    column-gap: 10px;
    align-items: center;
}

.gradingFeedbackAudioModuleButton {
    cursor: pointer;
    border: none;
    background-color: var(--new-primary-color);
    color: white;
    border-radius: 3px;
    height: 50px;
}

.gradingFeedbackAudioModuleIcon {
    font-size: 20px;
}

.gradingFeedbackAudioModuleTime {
    color: var(--new-off-text-color);
    font-weight: bold;
    margin: 0px;
}

.gradingFeedbackAudioModuleSliderWrapper {
    position: relative;
}

.gradingFeedbackAudioModuleSliderBar {
    position: absolute;
    background-color: var(--new-off-text-color);
    top: 50%;
    transform: translateY(-50%);
    height: 5px;
    width: 100%;
    border-radius: 3px;
    opacity: 0.3;
}

.gradingFeedbackAudioModuleSliderHandle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: var(--new-primary-color);
    border-radius: 50%;
    cursor: grab;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradingFeedbackAudioSlider {
    -webkit-appearance: none;
    /* margin-right: 15px; */
    height: 8px;
    background: rgba(200, 200, 200, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(
        to right,
        var(--new-primary-color),
        var(--new-primary-color)
    );
    background-repeat: no-repeat;
    background-size: 0% 100%;
    outline: none;
    border: none;
    cursor: pointer;
    align-self: center;
}

.gradingFeedbackAudioSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--new-primary-color);
    cursor: grab;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradingFeedbackAudioModuleTime {
    width: 30px;
}

.gradingFeedbackAudioModuleLoadingWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    padding-bottom: 10px;
}

@media(max-width: 500px) {
    .gradingFeedbackAudioHeader {
        font-size: 16px;
    }

    .gradingFeedbackAudioModuleWrapper {
        grid-template-columns: 40px auto 1fr auto;
    }

    .gradingFeedbackAudioModuleButton {
        height: 40px;
    }

    .gradingFeedbackAudioModuleIcon {
        font-size: 16px;
    }

    .gradingFeedbackAudioModuleTime {
        font-size: 14px;
        justify-self: center;
    }
}

@media(max-width: 370px) {
    .gradingFeedbackAudioModuleWrapper {
        column-gap: 5px;
    }
}