

.textAreaWrapper {
    display: grid;
    grid-template-rows: 300px auto;
}

.textAreaInput {
    resize: none;
    border: 3px solid #9ea7ac;
    padding: 5px;
    font-family: arial;
    font-size: 16px;
    border-radius: 0px;
    transition-duration: 300ms;
}

.textAreaInput:focus {
    border-radius: 0px;
    outline: none;
    border-color: #549cc9;
    box-shadow: 0px 0px 8px #659ce0;
}

.textAreaFooter {
    border: 3px solid #9ea7ac;
    border-top: 0px;
    padding: 10px 5px;
}

.textAreaWordCountText {
    margin: 0px;
    font-family: arial;
}
