

.uploadingFilePopupPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.uploadingFilePopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.uploadingFilePopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    /* height: 200px; */
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.uploadingFilePopupHeaderContainer {
    background-color: #334F75;
    height: 50px;
    padding-left: 10px;
    display: grid;
    align-items: center;
}

.uploadingFilePopupHeader {
    margin: 0px;
    color: white;
}

.uploadingFilePopupBodyWrapper {
    padding: 0px 20px;
}

.uploadingFilePopupText {

}

.uploadingFilePopupProgressBar {
    width: 100%;
    margin-bottom: 20px;
    height: 30px;
}