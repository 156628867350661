

.pdfViewerModal {
    /* position: absolute; */
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.50);
    width: auto;
    max-height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-rows: 40px 50px auto;
    --leftOffset: 0px;
    left: var(--leftOffset);
    --topOffset: 0px;
    top: var(--topOffset);
}

.pdfViewerHeader {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    background-color: #0283BE;
    padding: 0px 10px;
}

.pdfViewerHeader p {
    color: white;
    margin: 0px;
    font-size: 20px;
}

.pdfViewerHeader p:hover {
    cursor: move;
}

.exitButton {
    border: none;
    background: transparent;
    color: white;
    font-size: 16px;
}

.exitButton:hover {
    cursor: pointer;
}

.pdfViewerControls {
    display: grid;
    grid-template-columns: auto auto auto auto 1fr;
    background-color: #0283BE;
    padding: 10px;
    grid-column-gap: 10px;
}

.controlButtons {
    border: none;
    background-color: #1A517B;
    border-radius: 3px;
    padding: 8px;
}

.controlButtonSelected {
    background-color: #013257;
}

.controlButtons:hover {
    cursor: pointer;
}

.controlButtonIcons {
    color: white;
    font-size: 16px;
}

.pdfViewerZoomSelect {
    border: none;
    background-color: #1A517B;
    color: white;
    border-radius: 3px;
    font-size: 16px;
    padding: 0px 5px;
}

.pdfViewerZoomSelect:hover {
    cursor: pointer;
}

.pdfViewerZoomSelect option {
    background-color: #0283BE;
}

.pdfViewerContent {
    height: auto;
    width: 100%;
    overflow: auto;
    background: #455157;
}

.pdfBackdrop {
    
}

.pdfViewerDocument {
    width: fit-content;
}

.pdf {
    margin: 10px 30px;
    /* cursor: grab; */
    width: fit-content;
}

.textHighlightMark {
    /* background-color: rgb(255, 225, 52); */
    cursor: pointer;
}

.pdf canvas {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.70);
}

.rpv-core__inner-page {
    background-color: transparent !important;
    padding: 10px 0px;
}

.textHighlightButton {
    cursor: pointer;
    background-color: #1A517B;
    border: 0px;
    border-radius: 4px;
    padding: 5px;
}

.textHighlightIcon {
    color: white;
    font-size: 18px;
}
