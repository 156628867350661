

.viewCaseDetailsPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.viewCaseDetailsBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.viewCaseDetailsPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    height: fit-content;
    width: 95%;
    max-width: 450px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
}

.viewCaseDetailsPopupHeader {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    column-gap: 10px;
    grid-template-rows: auto auto;
    row-gap: 2px;
    padding: 10px;
    margin: 10px 0px;
}

.viewCaseDetailsPopupIdNumber {
    grid-row-start: 1;
    grid-row-end: 3;
    margin: 0px;
    font-size: 36px;
    color: var(--new-primary-color);
}

.viewCaseDetailsPopupTitle {
    margin: 0px;
    color: var(--new-text-color);
}

.viewCaseDetailsPopupMeta {
    margin: 0px;
    font-size: 12px;
    color: var(--new-text-color);
    opacity: 0.7;
}

.viewCaseDetailsPopupMoreOptionsButton {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 3;
    cursor: pointer;
    background-color: var(--new-secondary-background-color);
    border: none;
    border-radius: 3px;
    height: 35px;
    width: 35px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    align-self: center;
}

.viewCaseDetailsPopupMoreOptionsIcon {
    font-size: 20px;
    color: var(--new-text-color);
}

.viewCaseDetailsPopupCloseButton {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 4;
    cursor: pointer;
    background-color: var(--new-secondary-background-color);
    border: none;
    border-radius: 3px;
    height: 35px;
    width: 35px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    align-self: center;
    position: relative;
}

.viewCaseDetailsPopupCloseButton::before, .viewCaseDetailsPopupCloseButton::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    width: 2px;
    border-radius: 2px;
    background-color: var(--new-text-color);
}

.viewCaseDetailsPopupCloseButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.viewCaseDetailsPopupCloseButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.viewCaseDetailsPopupBody {
    background-color: var(--new-secondary-background-color);
    z-index: 5;
    position: relative;
    height: 420px;
}

.viewCaseDetailsPopupTabsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 1;
    position: relative;
    top: 1px;
}

.viewCaseDetailsPopupTab {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0px;
    background-color: var(--new-primary-background-color);
    height: 35px;
    cursor: pointer;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: var(--new-text-color);
}

.viewCaseDetailsPopupTabSelected {
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.viewCaseDetailsPopupSectionListWrapper {
    padding: 15px;
    padding-bottom: 10px;
    overflow-y: auto;
    height: 360px;
    box-sizing: border-box;
}

.viewCaseDetailsIncompleteCasesWrapper {
    padding: 15px;
    padding-bottom: 10px;
    overflow-y: auto;
    height: 360px;
    box-sizing: border-box;
}

.viewCaseDetailsIncompleteCase {
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    padding: 8px;
    margin-bottom: 10px;
    background-color: var(--new-secondary-background-color);
    transition-duration: 150ms;
}

.viewCaseDetailsIncompleteCaseSelected {
    background-color: var(--case-selected-color);
}

.viewCaseDetailsIncompleteCaseHeaderWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    row-gap: 5px;
}

.viewCaseDetailsIncompleteCaseHeaderExpanded {
    border-bottom: 1px solid var(--new-text-color);
    margin-bottom: 10px;
    padding-bottom: 5px;
    align-items: center;
}

.viewCaseDetailsIncompleteCaseTitle {
    margin: 0px;
    font-weight: 500;
    font-size: 16px;
    height: fit-content;
    align-self: center;
    color: var(--new-text-color);
}

.viewCaseDetailsIncompleteCaseTitleExpanded {
    /* grid-row-start: 1;
    grid-row-end: 3; */
}

.viewCaseDetailsIncompleteCaseMeta {
    margin: 0px;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.8;
    color: var(--new-text-color);
}

.viewCaseDetailsIncompleteCaseExpandButton {
    /* grid-row-start: 1;
    grid-row-end: 3; */
    grid-column: 2;
    width: 30px;
    height: 30px;
    align-self: center;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    margin-right: 5px;
}

.viewCaseDetailsIncompleteCaseExpandIcon {
    font-size: 26px;
    opacity: 0.4;
    color: var(--new-text-color);
}

.viewCaseDetailsIncompleteCaseBodyWrapper {

}

.viewCaseDetailsStartCaseButton {
    background-color: var(--new-secondary-color);
    color: white;
    border-radius: 3px;
    border: 0px;
    height: 35px;
    margin: 15px;
    margin-top: 10px;
    width: calc(100% - 30px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.viewCaseDetailsStartSpinnerWrapper {
    height: 60px;
    display: grid;
    align-items: center;
    justify-items: center;
}