

.writingAIFeedbackBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.writingAIFeedbackContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    background-color: white;
    border-radius: 3px;
    padding: 15px;
    width: 90%;
    max-width: 450px;
    height: fit-content;
    overflow: auto;
}

.writingAIFeedbackCloseButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
    border: 0px;
    background-color: transparent;
    font-size: 20px;
}

.writingAIFeedbackContainer > h2 {
    margin: 0px;
}

.writingAIFeedbackContainer > p {
    font-size: 14px;
    margin: 10px 0px 20px 0px;
}

.writingAIFeedbackBodyContainer {
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    max-height: 350px;
    overflow-y: auto;
}

.writingAIFeedbackBodyOptionContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin-top: 15px;
}

.writingAIFeedbackBodyOptionContainer:first-child {
    margin-top: 0px;
}

.writingAIFeedbackBodyOptionContainer > h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
}

.writingAIFeedbackGradeValueWrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-items: center;
    margin: 10px 0px;
}

.writingAIFeedbackGradeValueThumbButton, .writingAIFeedbackGradeValueButton {
    cursor: pointer;
    border: 1px solid var(--new-text-color);
    background-color: transparent;
    border-radius: 3px;
}

.writingAIFeedbackGradeValueThumbButton {
    height: 35px;
    width: 35px;
    font-size: 20px;
    color: var(--new-text-color);
    padding: 0px;
}

.writingAIFeedbackGradeValueButton {
    height: 25px;
    width: 25px;
}

.writingAIFeedbackGradeValueSelected {
    background-color: var(--new-primary-color);
    color: white;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media(max-width: 500px) {
    .writingAIFeedbackGradeValueThumbButton {
        height: 30px;
        width: 30px;
        font-size: 16px;
    }

    .writingAIFeedbackGradeValueButton {
        height: 20px;
        width: 20px;
    }
}

.writingAIFeedbackCommentBox {
    resize: none;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin-top: 5px;
    border: 1px solid var(--new-text-color);
    border-radius: 3px;
}

.writingAIFeedbackCommentButton {
    cursor: pointer;
    border: 0px;
    background-color: transparent;
    text-decoration: underline;
    color: var(--new-primary-color);
    padding: 0px 0px 5px 0px;
}

.writingAIFeedbackFooterContainer {
    display: grid;
    grid-template-columns: auto 175px;
    justify-items: end;
    align-items: center;
    column-gap: 15px;
    margin-top: 15px;
}

.writingAIFeedbackCancelButton, .writingAIFeedbackSubmitButton {
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    height: 40px;
    width: 100%;
    max-width: 175px;
}

.writingAIFeedbackCancelButton {
    background-color: var(--new-secondary-background-color);
    color: var(--new-primary-color);
}

.writingAIFeedbackSubmitButton {
    background-color: var(--new-primary-color);
    color: white;
}

.writingAIFeedbackLoadingWrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-items: center;
    width: 100%;
}