

.caseListIntroPageWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.caseListIntroContentWrapper {
    display: grid;
    justify-items: center;
    row-gap: 20px;
    position: relative;
    top: -10px;
    width: 90%;
    justify-self: center;
}

.caseListIntroTitle {
    text-align: center;
    margin: 0px;
    font-size: 26px;
    color: var(--new-text-color);
}

.caseListIntroSubtitle {
    text-align: center;
    margin: 0px;
    color: var(--new-text-color);
}

.caseListIntroSelect {
    height: 40px;
    max-width: 300px;
    width: 100%;
    border-radius: 3px;
    border: 0px;
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
    padding-left: 5px;
    color: var(--new-text-color);
}

.caseListIntroButton {
    max-width: 300px;
    width: 100%;
    height: 40px;
}

.caseListIntroNote {
    text-align: center;
    margin: 0px;
    font-size: 13px;
    opacity: 0.7;
    color: var(--new-text-color);
}

.caseListIntroStylesWrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    column-gap: 20px;
}

@media(max-width: 700px) {
    .caseListIntroStylesWrapper {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        justify-items: center;
    }
}

.caseListIntroStyleOptionContainer {
    max-width: 250px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: grid;
    border-radius: 3px;
    row-gap: 15px;
}

.caseListIntroStyleOptionTitle {
    margin: 0px;
    font-weight: 600;
    font-size: 20px;
    color: var(--new-text-color);
}

.caseListIntroStyleOptionText {
    margin: 0px;
    font-size: 13px;
    padding: 0px 10px;
    opacity: 0.8;
    color: var(--new-text-color);
}

.caseListIntroStyleOptionButton {
    height: 40px;
    width: 100%;
}

.caseListIntroStyleOrText {
    color: var(--new-text-color);
}

.caseListIntroSignatureInput {
    height: 40px;
    max-width: 300px;
    width: 100%;
    border-radius: 3px;
    border: 0px;
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    padding-left: 10px;
    color: var(--new-text-color);
    box-sizing: border-box;
}