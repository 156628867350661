

.emailWrapper {
    background-color: #F5F7F8;
    padding: 10px;
}

.email {
    border: 1px solid black;
    width: 100%;
    max-width: 600px;
    background-color: #E7E7E8;
}

.emailHeader {
    display: grid;
    grid-template-columns: 100px auto;
    margin: 30px;
}

.emailHeaderText {
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0px;
}

.emailHeaderContent {
    background-color: white;
    border: 1px solid black;
    margin: -1px;
    padding-left: 10px;
    display: grid;
    align-items: center;
}

.emailHeaderContentText {
    font-size: 18px;
    margin: 0px;
}


.emailBody {
    border: 1px solid black;
    margin: 30px;
    background-color: white;
}

.emailBodyContent {
    margin: 0px 5px 5px 5px;
    white-space: pre-wrap;
    line-height: 1.7;
    font-size: 18px;
}