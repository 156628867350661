
.gradingFeedbackWritingComponentContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px 0px;
    margin: 15px;
    overflow: hidden;
    overflow-x: auto;
}

.gradingFeedbackInfoComponentContainer {
    color: var(--new-text-color);
}

@media(max-width: 750px) {
    .gradingFeedbackInfoComponentContainer {
        font-size: 14px;
    }
}

.gradingFeedbackWritingHeader {
    margin-top: 5px;
    padding-left: 15px;
    color: var(--new-text-color);
    position: sticky;
    left: 0;
}

.gradingFeedbackWritingTableContainer {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    color: var(--new-text-color);
    min-width: 575px;
    overflow-x: auto;
}

@media(max-width: 750px) {
    .gradingFeedbackWritingTableContainer {
        font-size: 12px;
    }
}

.gradingFeedbackUserWritingResponseExpanded,
.gradingFeedbackUserWritingResponseCollapsed {
    position: relative;
    padding-bottom: 30px;
}

.gradingFeedbackUserWritingResponseExpanded {
    height: fit-content;
}

.gradingFeedbackUserWritingResponseCollapsed {
    height: 200px;
    overflow: hidden;
}

.gradingFeedbackWritingTableContainer > thead > tr > th, .gradingFeedbackWritingTableContainer > tbody > tr > td, .gradingFeedbackWritingTableContainer > tfoot > tr > td {
    padding: 0px 15px;
}

.gradingFeedbackWritingTableContainer > thead > tr {
    background-color: var(--new-secondary-background-color) !important;
}

.gradingFeedbackWritingTableBodyContainer {
    text-align: center;
}

.gradingFeedbackWritingTableFooterContainer {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.gradingFeedbackWritingTableFooterContainer > tr {
    background-color: var(--new-secondary-background-color) !important;
    height: 40px !important;
}

.gradingFeedbackWritingTablePrimaryColumn {
    text-align: left;
}

tr {
    height: 30px;
}

tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.gradingFeedbackWritingExpandButton {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: var(--new-primary-color);
    color: white;
    border: 0px;
    border-radius: 0px 0px 3px 3px;
    cursor: pointer;
}

.gradingFeedbackWritingSectionContainer {
    position: relative;
    background-color: var(--new-secondary-background-color);
    margin: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    color: var(--new-text-color);
}

.gradingFeedbackWritingSectionHeader {
    font-size: 24px;
    margin-bottom: 5px;
    margin-top: 0px;
    color: var(--new-text-color);
}

.gradingFeedbackWritingSectionSubheader {
    font-size: 14px;
    opacity: 0.5;
    margin-top: 5px;
    font-weight: bold;
    color: var(--new-text-color);
}

.gradingFeedbackFormattingContentWrapper {
    padding: 15px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    row-gap: 15px;
}

@media(max-width: 1100px) {
    .gradingFeedbackFormattingContentWrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }
}

@media(max-width: 650px) {
    .gradingFeedbackFormattingContentWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
    }
}

.gradingFeedbackFormattingContentCard {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
}

.gradingFeedbackFormattingContentCardHeader {
    display: grid;
    grid-template-columns: 45px 1fr;
    grid-template-rows: auto auto;
    row-gap: 5px;
    align-items: center;
}

.gradingFeedbackFormattingContentCardHeaderIcon {
    grid-row-start: 1;
    grid-row-end: 3;
    color: var(--new-primary-color);
    font-size: 32px;
}

.gradingFeedbackFormattingContentHeader {
    margin: 0px;
    font-size: 22px;
    font-weight: normal;
}

@media(max-width: 750px) {
    .gradingFeedbackFormattingContentCardHeader {
        grid-template-columns: 35px 1fr;
    }

    .gradingFeedbackFormattingContentCardHeaderIcon {
        font-size: 26px;
    }

    .gradingFeedbackFormattingContentHeader {
        font-size: 18px;
    }
}

.gradingFeedbackFormattingContentSubheader {
    margin: 0px;
    font-size: 12px;
    opacity: 0.5;
    font-weight: bold;
}

.gradingFeedbackFormattingContentCardItem {
    display: grid;
    grid-template-columns: 15px 1fr;
    align-items: center;
    column-gap: 15px;
    margin-top: 10px;
    padding: 0px 5px;
}

.gradingFeedbackCorrectIcon, .gradingFeedbackIncorrectIcon {
    font-size: 18px;
}

.gradingFeedbackCorrectIcon {
    color: green;
}

.gradingFeedbackIncorrectIcon {
    color: red;
}

.gradingFeedbackFormattingContentCardItem > p {
    font-size: 12px;
    margin: 0px;
}

.gradingFeedbackWordCountContentWrapper {
    padding: 15px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
}

@media(max-width: 700px) {
    .gradingFeedbackWordCountContentWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
}

.gradingFeedbackWordCountContentCard {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    display: grid;
}

.gradingFeedbackWordCountContent {
    height: fit-content;
    align-self: center;   
}

.gradingFeedbackWordCountContentCardHeader {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 50px 1fr;
    align-items: center;
}

.gradingFeedbackWordCountContentCardHeader > svg {
    grid-row-start: 1;
    grid-row-end: 3;
    color: var(--new-primary-color);
    font-size: 40px;
}

.gradingFeedbackWordCountContentCardHeader > h4 {
    margin: 0px;
    font-size: 20px;
    font-weight: normal;
}

.gradingFeedbackWordCountContentCardHeader > p {
    margin: 0px;
    font-size: 12px;
    opacity: 0.4;
    font-weight: bold;
    margin-top: 5px;
}

@media(max-width: 750px) {
    .gradingFeedbackWordCountContentCardHeader > h4 {
        font-size: 18px;
    }
}

.gradingFeedbackWordCountContentCardText {
    margin: 0px;
    margin-top: 10px;
    font-size: 14px;
}

.gradingFeedbackWritingContentWrapper {
    padding: 15px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-auto-flow: row;
    row-gap: 15px;
    max-height: 350px;
    overflow-y: hidden;
    box-sizing: border-box;
    color: initial;
}

.gradingFeedbackWritingContentExpanded {
    max-height: fit-content;
}

.expandableContentContainer {
    padding-bottom: 45px;
}

.gradingFeedbackContentItemContainer {
    display: grid;
    grid-template-columns: 50px 1fr;
    overflow: hidden;
    border-radius: 3px;
    min-height: 60px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    align-items: center;
}

.gradingFeedbackContentItemCorrect {
    background-color: #F2FFEF;
}

.gradingFeedbackContentItemIncorrect {
    background-color: #FFECEB;
}

.gradingFeedbackContentItemContainer > div {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
}

.gradingFeedbackContentItemCorrect > div {
    background-color: #507C37;
}

.gradingFeedbackContentItemIncorrect > div {
    background-color: #B53333;
}

.gradingFeedbackContentItemContainer > div > svg {
    color: white;
    font-size: 20px;
}

.gradingFeedbackContentItemContainer > p {
    margin: 15px;
    font-weight: 500;
}

@media(max-width: 750px) {
    .gradingFeedbackContentItemContainer > p {
        font-size: 14px;
    }
}

.gradingFeedbackGrammarContentItem {
    display: grid;
    grid-template-columns: 50px auto;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    color: initial;
    position: relative;
}

.gradingFeedbackGrammarMoreOptionsButton {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    font-size: 24px;
    cursor: pointer;
    padding: 12px;
    opacity: 0.6;
}

.gradingFeedbackGrammarContentItemCorrect {
    background-color: #F2FFEF;
}

.gradingFeedbackGrammarContentItemIncorrect {
    background-color: #FFECEB;
}

.gradingFeedbackGrammarContentItemCorrect > .gradingFeedbackGrammarContentIconWrapper {
    background-color: #507C37;
}

.gradingFeedbackGrammarContentItemIncorrect > .gradingFeedbackGrammarContentIconWrapper {
    background-color: #B53333;
}

.gradingFeedbackGrammarContentIconWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    background-color: #507C37;
}

.gradingFeedbackGrammarContentIconWrapper > svg {
    color: white;
    font-size: 24px;
}

.gradingFeedbackGrammarContentTextWrapper {
    padding: 15px;
}

.gradingFeedbackGrammarContentTextWrapper > h4 {
    font-size: 20px;
    margin: 0px;
    font-weight: 600;
}

.gradingFeedbackGrammarContentSubtitle {
    margin: 0px;
    font-size: 14px;
    margin-top: 5px;
}

.gradingFeedbackGrammarContentTextWrapper > div {
    background-color: rgba(0, 0, 0, 0.075);
    padding: 15px;
    border-radius: 3px;
    margin: 15px 0px;
}

.gradingFeedbackGrammarContentTextWrapper > div > p {
    margin: 0px;
    font-size: 14px;
}

.gradingFeedbackGrammarContentCorrectValueText {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
}

.gradingFeedbackGrammarOptionsContainer {
    position: absolute;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    min-width: 200px;
    box-sizing: border-box;
}

.gradingFeedbackGrammarOption {
    cursor: pointer;
    background-color: var(--new-primary-color);
    color: white;
    border: 0px;
    width: 100%;
    padding: 10px 0px;
    border-radius: 3px;
}

.gradingFeedbackWritingNoGrammarText {
    font-size: 14px;
    margin: 0px;
    padding: 15px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media(max-width: 750px) {
    .gradingFeedbackWritingHeader, .gradingFeedbackWritingSectionHeader {
        font-size: 18px;
    }

    .gradingFeedbackWritingSectionSubheader {
        font-size: 12px;
    }

    .gradingFeedbackGrammarContentTextWrapper > h4 {
        font-size: 16px;
    }

    .gradingFeedbackGrammarContentSubtitle {
        font-size: 12px;
    }

    .gradingFeedbackGrammarContentTextWrapper > div > p {
        font-size: 12px;
    }

    .gradingFeedbackGrammarContentCorrectValueText {
        font-size: 12px;
    }
}