.simulationFooterWrapper {
    height: 43px;
    background-color: var(--primary-color);
    border-top: 4px solid var(--secondary-color);
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    justify-items: end;
    padding: 5px 10px;
    column-gap: 5px;
}

.simulationFooterButton {
    background-color: var(--secondary-color);
    color: white;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    height: 100%;
    width: fit-content;
    display: grid;
    grid-template-columns: auto 13px;
    align-items: center;
    column-gap: 5px;
    font-size: 16px;
    padding: 5px 10px;
}

.simulationFooterButton:nth-child(1) {
    grid-template-columns: 13px auto;
}

.buttonDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.chevronIcon {
    height: 100%;
    max-height: 17px;
}